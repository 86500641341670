const cardData = [
  {
    img: '/assets/images/facilities/environment.jpg',
    title: 'Environment',
    content:
      'The School is located in the serene atmosphere and enjoys agreen ambience. There are more than 300 trees in the campus which keep the place cool and pollution free.',
  },
  {
    img: '/assets/images/facilities/class.jpg',
    title: 'Class Rooms',
    content:
      ' Very spacious well ventilated, well lighted and comfortable class rooms with suitable furniture.',
  },
  {
    img: '/assets/images/facilities/class.jpg',
    title: 'Smart Classes',
    content:
      'The audio visual learning support is given through smart classes, which serve as a powerful tool in promoting meaningful learning.',
  },
  {
    img: '/assets/images/facilities/computer.jpg',
    title: 'Computer Lab',
    content:
      'A High-Tech modern computer laboratory with updated software and high configuration computers.',
  },
  {
    img: '/assets/images/facilities/chemistry.jpg',
    title: 'Laboratory',
    content:
      'Our School has fully equipped laboratories with all necessary apparatus and instruments to suit the prescribed syllabus, Practical classes are conducted regularly to inculcate learning by doing. ',
  },
  {
    img: '/assets/images/facilities/library.jpg',
    title: 'Library',
    content:
      'A well stocked library with around  12,000 books suitable to different age groups are available at the library ample reference materials and news papers and magazines  for the use of faculty and the students are also available.  ',
  },
  {
    img: 'assets/images/facilities/playground.jpeg',
    title: 'Playgrounds',
    content:
      'Vast play ground with Volley ball & throw ball courts, foot ball ground, 400m concrete track for Athletic practice, Cycling and Skating. ',
  },
  {
    img: '/assets/images/facilities/kidsplayground.jpeg',
    title: 'Play Area For Kids',
    content: 'Play area provides joyful opportunities for children to play.',
  },
  {
    img: '/assets/images/facilities/bus.jpg',
    title: 'Potable Water',
    content:
      'RO water plant is installed and safe drinking water facility is made available in all wings.',
  },
  {
    img: '/assets/images/facilities/bus.jpg',
    title: 'Transport',
    content:
      'The school has a fleet of buses which mobilize students to and fro. The buses are manned by experienced driver several bus stops have been identified to aid convenient pick up and drop for students. Buses are always maintained in good condition. ',
  },
  {
    img: '/assets/images/facilities/canteen.jpeg',
    title: 'Canteen',
    content:
      'Breakfast and lunch is made available to the students and staff at nominal cost.The food is tasty and nutritious. Strict standard of hygiene is maintained.',
  },
  {
    img: '/assets/images/facilities/communication.jpeg',
    title: 'Communication',
    content:
      'Training is given to students in communicative English and personality development by specially trained faculty. ',
  },
  {
    img: '/assets/images/facilities/neet.JPG',
    title: 'Coaching Class For Neet And Engineering Entarnce',
    content:
      'Foundation program and NEET coaching classes are conducted by reputed institute to students from VII to XII STD.',
  },
]

export default cardData
