const kinderGardenList = [
  'Kindergarten curriculum is structured to give the young buds in the age group 2.5 to 5, the warmth and security of home and help them at the same time to grow with confidence.',
  'The children are given ample space to grow and express themselves.',
  'Developing language skills through phonetics.',
  'Story telling.',
  'Simple exercises to develop motor skills.',
  'Enhances Listening and Speaking  by innovative methods.',
  'Teaching buddies to be Compassionate and  brave.',
  'Play area with modern equipments installed.',
]

export default kinderGardenList
