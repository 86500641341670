import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBlock: 8,
  },
  title: {
    color: theme.palette.custom.text,
    paddingBlockStart: 20,
  },
  boardMembersContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    gap: 20,
    paddingBlockStart: 20,
  },
  boardMembersWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: 320,
  },
  imgContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  img: {
    width: 160,
    height: 180,
    borderRadius: 10,
    border: '1px solid #c0c0c0',
  },
  label: {
    color: theme.palette.custom.text,
    fontFamily: 'Lato !important',
    textAlign: 'center',
    paddingBlockStart: 6,
  },
}))

export default useStyles
