import Header from '../../Components/Header'
import Sidebar from '../../Components/Sidebar'
import useStyles from './style'
import Sections from '../../Components/Sections'
import Footer from '../../Components/Footer'

const HomeLayout = () => {
  const classes = useStyles()
  return (
    <>
      <Header />
      <Sidebar />
      <section className={classes.layout}>
        <Sections />
      </section>
      <Footer />
    </>
  )
}

export default HomeLayout
