import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material'
import useStyles from './style'
import TextDivider from '../../Components/TextDivider'
import { languagesRow, groupsRow } from '../../Models/Admission/Tables'

const Admission = () => {
  const classes = useStyles()
  return (
    <>
      <section className={classes.root}>
        <Typography
          variant="h4"
          sx={{ fontWeight: 600 }}
          className={classes.title}
        >
          Admission Information
        </Typography>
        <TextDivider />
        <Typography
          gutterBottom
          variant="h5"
          style={{ paddingBlockStart: 12 }}
          className={classes.label}
        >
          Admission is primarily open for LKG & XI Std only. For other classes,
          limited vacancies are filled up on the basis of merit.
        </Typography>
        <Typography
          sx={{ fontWeight: 600 }}
          variant="h5"
          className={classes.title}
        >
          L.K.G
        </Typography>
        <TextDivider />
        <Typography
          gutterBottom
          variant="h5"
          style={{ paddingBlockStart: 12 }}
          className={classes.label}
        >
          Children are required to be 3 years as on 30th June of the academic
          year of admission sought. Birth certificate is mandatory for age
          proof.
        </Typography>
        <Typography
          sx={{ fontWeight: 600 }}
          variant="h5"
          className={classes.title}
        >
          Higher Secondary Courses
        </Typography>
        <TextDivider />
        <Typography
          gutterBottom
          variant="h5"
          style={{ paddingBlockStart: 12 }}
          className={classes.label}
        >
          Admission to class XI is based on the marks obtained in the class X
          board Examination.
        </Typography>
        <Typography
          sx={{ fontWeight: 600 }}
          gutterBottom
          variant="h5"
          className={classes.label}
        >
          Language Subjects
        </Typography>
        <Grid container className={classes.languageTableContainer}>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead className={classes.TableHeader}>
                  <TableRow>
                    <TableCell>
                      <Typography variant="body1" className={classes.label}>
                        S.No.
                      </Typography>
                    </TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right">
                      <Typography variant="body1" className={classes.label}>
                        Subjects
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {languagesRow.map((row) => (
                    <TableRow
                      key={row.sNo}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <Typography variant="body1" className={classes.label}>
                          {row.sNo}
                        </Typography>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Typography variant="body1" className={classes.label}>
                          {row.part}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant="body1" className={classes.label}>
                          {row.subjects}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <Typography
          sx={{ fontWeight: 600 }}
          gutterBottom
          variant="h5"
          className={classes.label}
          style={{ paddingBlockStart: 20 }}
        >
          Groups Offered
        </Typography>
        <Grid container className={classes.languageTableContainer}>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead className={classes.TableHeader}>
                  <TableRow>
                    <TableCell>
                      <Typography variant="body1" className={classes.label}>
                        Groups
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="body1" className={classes.label}>
                        Subjects
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {groupsRow.map((row) => (
                    <TableRow
                      key={row.groupNo}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <Typography variant="body1" className={classes.label}>
                          {row.groupNo}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant="body1" className={classes.label}>
                          {row.subjects}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </section>
    </>
  )
}

export default Admission
