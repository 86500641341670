const hostelFacilities = [
  'Our hostel is a home away from home.',
  'Separate blocks for boys and girls. Rooms are allotted on sharing basis and individual lockers are provided.',
  'Providing parental care to each inmate is ensured.',
  'The hostel provides an ideal atmosphere to pursue education. The hostel room is spacious and well ventilated.',
  'Round the clock first Aid and medical facilities are available.',
  'Tasty, nutritious food is provided during breakfast lunch and dinner. Hygiene is emphasized in every aspect.',
  'Fine dining facilities are available.',
]

export default hostelFacilities
