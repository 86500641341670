import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBlockStart: 12,
  },
  title: {
    color: theme.palette.custom.text,
    paddingBlock: 4,
  },
  label: {
    color: theme.palette.custom.text,
    fontFamily: 'Lato !important',
    paddingBlock: 4,
  },
  languageTableContainer: {
    paddingBlockStart: 20,
  },
  TableHeader: {
    background: '#d1d7f5',
  },
}))

export default useStyles
