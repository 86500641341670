import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    color: theme.palette.custom.text,
    paddingBlock: 12,
  },
  labels: {
    fontFamily: 'Lato !important',
    color: theme.palette.custom.text,
    paddingBlock: 12,
  },
  list: {
    display: 'flex',
    alignItems: 'center',
    paddingBlock: 14,
  },
  bulletListIcon: {
    color: '#ffd700',
  },
  listText: {
    fontFamily: 'Lato !important',
    color: theme.palette.custom.text,
    paddingInline: 8,
  },
  awardsLabel: {
    fontFamily: 'Lato !important',
    color: theme.palette.custom.text,
    display: 'flex',
    alignItems: 'flex-start',
    paddingBlock: 12,
  },
  notableAchievements: {
    display: 'flex',
    alignItems: 'flex-start',
    paddingBlock: 14,
  },
  achievements: {
    fontFamily: 'Lato !important',
    color: theme.palette.custom.text,
  },
}))

export default useStyles
