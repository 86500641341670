import TextDivider from '../../Components/TextDivider'
import { Typography } from '@mui/material'
import useStyles from './style'
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import notableAchievements from '../../Models/Awards/NotableAchievements'
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium'

const AwardsAndAchievements = () => {
  const classes = useStyles()
  return (
    <>
      <Typography
        variant="h4"
        sx={{ fontWeight: 600 }}
        style={{ paddingBlockStart: 20 }}
        className={classes.title}
      >
        Special Awards
      </Typography>
      <TextDivider />
      <Typography
        variant="h5"
        style={{ paddingBlockStart: 20 }}
        className={classes.labels}
      >
        We are proud to announce that our management has instituted the
        following awards to encourage our students.
      </Typography>
      <section className={classes.list}>
        <EmojiEventsIcon
          sx={{ fontSize: 24, fontWeight: 600 }}
          className={classes.bulletListIcon}
        />
        <div>
          <Typography
            sx={{ fontWeight: 600 }}
            variant="h5"
            className={classes.listText}
          >
            SEVARATNA Dr. R. VENKATESALU AWARD
          </Typography>
        </div>
      </section>
      <Typography variant="h5" className={classes.awardsLabel}>
        &nbsp; &nbsp; &nbsp; &nbsp; <KeyboardArrowRightIcon /> This award
        carries a cash prize, a Certificate and a Shield. This is given to the
        student who scores the highest total in our school in XII Std Public
        Examination.
      </Typography>
      {/* award2 */}
      <section className={classes.list}>
        <EmojiEventsIcon
          sx={{ fontSize: 24, fontWeight: 600 }}
          className={classes.bulletListIcon}
        />
        <div>
          <Typography
            sx={{ fontWeight: 600 }}
            variant="h5"
            className={classes.listText}
          >
            Smt.VELUMANI VENKATESALU AWARD
          </Typography>
        </div>
      </section>
      <Typography variant="h5" className={classes.awardsLabel}>
        &nbsp; &nbsp; &nbsp; &nbsp; <KeyboardArrowRightIcon />
        This award carries a cash prize , a Certificate and a Shield. This is
        given to the Top-Ranking Student in the SSLC Public Examination.
      </Typography>
      {/* award3 */}
      <section className={classes.list}>
        <EmojiEventsIcon
          sx={{ fontSize: 24, fontWeight: 600 }}
          className={classes.bulletListIcon}
        />
        <div>
          <Typography
            sx={{ fontWeight: 600 }}
            variant="h5"
            className={classes.listText}
          >
            V.R.AWARD
          </Typography>
        </div>
      </section>
      <Typography variant="h5" className={classes.awardsLabel}>
        &nbsp; &nbsp; &nbsp; &nbsp; <KeyboardArrowRightIcon />
        This award is given to the students who secure a State Rank or Centum
        Marks in more than 3 subjects in XII Standard Public Examination.
      </Typography>
      {/* award4 */}
      <section className={classes.list}>
        <EmojiEventsIcon
          sx={{ fontSize: 24, fontWeight: 600 }}
          className={classes.bulletListIcon}
        />
        <div>
          <Typography
            sx={{ fontWeight: 600 }}
            variant="h5"
            className={classes.listText}
          >
            V.G.AWARD
          </Typography>
        </div>
      </section>
      <Typography variant="h5" className={classes.awardsLabel}>
        &nbsp; &nbsp; &nbsp; &nbsp; <KeyboardArrowRightIcon />
        This award is given to the students who secure a total of 485 marks out
        of 500 or Centum Marks in at least 3 Subjects. The recipient will be
        given Free Education in both the years of the Higher Secondary Course.
      </Typography>

      {/* Notable Acheievements */}
      <Typography
        variant="h4"
        sx={{ fontWeight: 600 }}
        style={{ paddingBlockStart: 20 }}
        className={classes.title}
      >
        Notable Achievements
      </Typography>
      <TextDivider />
      {notableAchievements?.map((data) => (
        <section className={classes.notableAchievements} key={data?.name}>
          <section>
            <WorkspacePremiumIcon
              sx={{ fontSize: 24, fontWeight: 600 }}
              className={classes.bulletListIcon}
            />
          </section>
          <section>
            {' '}
            <Typography
              variant="h5"
              className={classes.achievements}
              key={data?.name}
            >
              <span style={{ fontWeight: 600 }}>
                {data?.year}: {data?.name}
              </span>{' '}
              {data?.achievement}
            </Typography>
          </section>
        </section>
      ))}
    </>
  )
}

export default AwardsAndAchievements
