import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '80vh',
  },

  sliderWrapper: {
    paddingBlockStart: 32,
    paddingBlockEnd: 44,
  },
  imgContainer: {
    [theme.breakpoints.up('xs')]: {
      width: 100,
      height: 120,
    },
    [theme.breakpoints.up('sm')]: {
      width: 200,
      height: 220,
      WebkitBoxReflect:
        'below 1px linear-gradient(transparent, transparent,#0005)',
      display: 'flex',
      borderRadius: 15,
    },
    [theme.breakpoints.up('md')]: {
      width: 240,
      height: 260,
    },
    [theme.breakpoints.up('lg')]: {
      width: 300,
      height: 320,
    },
    [theme.breakpoints.up('xl')]: {
      width: 400,
      height: 420,
    },
    [theme.breakpoints.up('1950')]: {
      width: 570,
      height: 590,
    },
  },
  sliderImg: {
    borderRadius: 10,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  titleWrapper: {
    [theme.breakpoints.up('xs')]: {
      width: 100,
    },
    [theme.breakpoints.up('sm')]: {
      width: 200,
    },
    [theme.breakpoints.up('md')]: {
      width: 240,
    },
    [theme.breakpoints.up('lg')]: {
      width: 300,
    },
    [theme.breakpoints.up('xl')]: {
      width: 400,
    },
    [theme.breakpoints.up('1950')]: {
      width: 570,
    },
  },
  title: {
    color: theme.palette.custom.dark,
    textAlign: 'center',
  },
}))

export default useStyles
