const notableAchievements = [
  {
    year: 1994,
    name: 'R.SANGEETHA A',
    achievement:
      'scored the highest total of 1126/1200 in Tirupur Education District and also received the Anna Award and Scholarship from Government of the year 1993-94',
  },
  {
    year: 1997,
    name: 'B.R.RAJESWARI',
    achievement:
      'secured state III Rank in General Machinist group.She also scored the highest total in Tirupur Educational District-1147/1200',
  },
  {
    year: 1998,
    name: 'R.BARATH VIKRAM',
    achievement: `received the Chief Minister's Award for scoring the highest total in Coimbatore Revenue District (with Tamil as II language) 1158/1200 . He was eligible for Free education through out his Medical course`,
  },
  {
    year: 1999,
    name: 'S.DHAYALAN',
    achievement:
      'secured the State First Rank in Matriculation public Examination by scoring 1049/1100',
  },
  {
    year: 2000,
    name: 'D.PARTHIBAN, 1164/1200 T.R.DEVENDRAN 1133/1200',
    achievement:
      'have been awarded the prestigious Anna Award and Scholarship given by the Government of TamilNadu',
  },
  {
    year: 2008,
    name: 'V.VIJAY PRASANTH',
    achievement: `scored the highest total of 1178/1200 in XII std public Examination in Coimbatore Revenue Distric (includes pollachi , tirupur and coimbatore Education Districts) and he received the chief minister's Award from the chief minister of TamilNadu and he was eligible for free education throughout his Engineering Course`,
  },
  {
    year: 2011,
    name: 'M.NELSON FINNEY',
    achievement:
      'secured the state II Rank in General Machinist Group and has received the Gandhi Memorial Award given by the Government of TamilNadu',
  },
  {
    year: 2014,
    name: 'J.MELBA',
    achievement:
      'secured the state I Rank in General Machinist group by scoring a total of 1185-1200',
  },
  {
    year: 2015,
    name: 'N.Manoj',
    achievement:
      'secured the state I Rank in General  Machinist group by scoring a total of 1177/1200.',
  },
  {
    year: 2017,
    name: 'R.Deepika',
    achievement: 'Secured Ist mark by scoring 1184/1200 ',
  },
  {
    year: 2017,
    name: 'R.Soundarya',
    achievement:
      'secured centum in all four major subjects brought pride to our school.',
  },
  {
    year: 2017,
    name: 'S.B.Haripriya',
    achievement: 'cleared NEET Exam and got the admission in MBBS.',
  },
]

export default notableAchievements
