const sliderData = [
  { name: 'Playground', imgUrl: '/assets/images/homeSlider/01.jpg' },
  { name: 'Opening Gate', imgUrl: '/assets/images/homeSlider/02.jpg' },
  { name: 'Architecture', imgUrl: '/assets/images/homeSlider/03.jpg' },
  { name: 'Environment', imgUrl: '/assets/images/homeSlider/04.jpg' },
  { name: 'Parade', imgUrl: '/assets/images/homeSlider/05.jpg' },
  { name: 'Music Classes', imgUrl: '/assets/images/homeSlider/06.jpg' },
  { name: 'Activities', imgUrl: '/assets/images/homeSlider/07.jpg' },
  { name: 'Garden', imgUrl: '/assets/images/homeSlider/08.jpg' },
  { name: 'Buses', imgUrl: '/assets/images/homeSlider/09.jpg' },
  { name: 'Gardening', imgUrl: '/assets/images/homeSlider/10.JPG' },
]
export default sliderData
