import React from 'react'
import { Grid } from '@mui/material'
import Home from '../../Pages/Home'
import About from '../../Pages/About'
import Facilities from '../../Pages/Facilities'
import Admission from '../../Pages/Admission'
import Academics from '../../Pages/Academics'
import AwardsAndAchieveMents from '../../Pages/AwardsAndAchievements'

const Sections = () => {
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <section id="home">
            <Home />
          </section>
          <section id="about">
            <About />
          </section>
          <section id="facilities">
            <Facilities />
          </section>
          <section id="admission">
            <Admission />
          </section>
          <section id="academics">
            <Academics />
          </section>
          <section id="awards">
            <AwardsAndAchieveMents />
          </section>
        </Grid>
      </Grid>
    </>
  )
}

export default Sections
