import { Typography } from '@mui/material'
import useStyles from './style'
import TextDivider from '../../Components/TextDivider'
import boardMembers from '../../Models/About/BoardMembers'
import admins from '../../Models/About/Administration'

const About = () => {
  const classes = useStyles()
  return (
    <>
      <section className={classes.root}>
        {/* Board Directors Grid */}
        <Typography
          variant="h4"
          sx={{ fontWeight: 600 }}
          className={classes.title}
        >
          Board Of Trustees
        </Typography>
        <TextDivider />
        <section className={classes.boardMembersContainer}>
          {boardMembers?.map((data) => (
            <div className={classes.boardMembersWrapper} key={data?.name}>
              <div className={classes.imgContainer}>
                <img src={data?.img} alt={data?.name} className={classes.img} />
              </div>
              <div style={{ paddingBlockStart: 8 }}>
                <Typography variant="h5" className={classes.label}>
                  {data?.position}
                </Typography>
              </div>
              <div>
                <Typography variant="h5" className={classes.label}>
                  {data?.workPlace}
                </Typography>
              </div>
            </div>
          ))}
        </section>

        {/* Administration Departments Grid */}
        <Typography
          variant="h4"
          sx={{ fontWeight: 600 }}
          className={classes.title}
        >
          Administration
        </Typography>
        <TextDivider />
        <section className={classes.boardMembersContainer}>
          {admins?.map((data) => (
            <div className={classes.boardMembersWrapper} key={data?.name}>
              <div className={classes.imgContainer}>
                <img src={data?.img} alt={data?.name} className={classes.img} />
              </div>
              <div style={{ paddingBlockStart: 8 }}>
                <Typography variant="h5" className={classes.label}>
                  {data?.position}
                </Typography>
              </div>
              <div>
                <Typography variant="h5" className={classes.label}>
                  {data?.workPlace}
                </Typography>
              </div>
            </div>
          ))}
        </section>
      </section>
    </>
  )
}

export default About
