const list = [
  'Year of establishment - 1981.',
  'Lowest Fee structure highest quality of education.',
  'Peaceful  and Pollution free  atmosphere.',
  'Dedicated, qualified and experienced faculty.',
  'Meaningful Education.',
  'Character building.',
  'Consistent  100% Success in Public Exams since 1989.',
  'Excellent Infrastructure.',
]

export default list
