import { createTheme } from '@mui/material/styles' // Create a theme instance
const theme = createTheme({
  palette: {
    custom: {
      main: '#7047EA',
      hover: 'rgb(112, 71, 234,0.9)',
      light: '#8C6BEE',
      dark: '#4E31A3',
      text: '#596784',
      divider: '#81899b',
    },
    // primary: {
    //   main: '#00C271',
    //   light: '#33CE8D',
    //   dark: '#00874F',
    //   contrastText: '#ffffff',
    // },
    // secondary: {
    //   main: '#070123',
    //   light: '#38334F',
    //   dark: '#040018',
    //   contrastText: '#ffffff',
    // },
    error: {
      main: '#ff2020',
      light: '#ff644c',
      dark: '#c30000',
      contrastText: '#FF2083',
    },
    warning: {
      main: '#ff9800',
      light: '#ffb74d',
      dark: '#f57c00',
    },
    info: {
      main: '#2196f3',
      light: '#64b5f6',
      dark: '#1976d2',
    },
    success: {
      main: '#4caf50',
      light: '#81c784',
      dark: '#388e3c',
      contrastText: '#0CC593',
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        fontFamily: 'Poppins',
      },
      variants: [
        {
          props: { variant: 'h1' },
          style: {
            fontFamily: 'Poppins',
            fontSize: 60,
            fontWeight: 500,
            fontStyle: 'normal',
            // color: '#000',
          },
        },
        {
          props: { variant: 'h2' },
          style: {
            fontFamily: 'Poppins',
            fontSize: 38,
            fontWeight: 600,
            fontStyle: 'normal',
            // color: '#000',
            lg: {
              fontSize: 26,
            },
            md: {
              fontSize: 22,
            },
            sm: {
              fontSize: 18,
            },
          },
        },
        {
          props: { variant: 'h3' },
          style: {
            ffontFamily: 'Poppins',
            fontSize: 28,
            fontWeight: 500,
            fontStyle: 'normal',
            // color: '#000',
          },
        },
        {
          props: { variant: 'h4' },
          style: {
            fontFamily: 'Poppins',
            fontSize: 24,
            fontStyle: 'normal',
            // color: '#000',
            lg: {
              fontSize: 24,
            },
            md: {
              fontSize: 18,
            },
            sm: {
              fontSize: 14,
            },
          },
        },
        {
          props: { variant: 'h5' },
          style: {
            fontFamily: 'Poppins',
            fontSize: 18,
            fontWeight: 400,
            fontStyle: 'normal',
            // color: '#000',
          },
        },
        {
          props: { variant: 'h6' },
          style: {
            fontFamily: 'Poppins',
            fontSize: 14,
            fontWeight: 400,
            fontStyle: 'normal',
            // color: '#000',
          },
        },
        {
          props: { variant: 'body1' },
          style: {
            fontFamily: 'Poppins',
            fontSize: 16,
            fontWeight: 400,
            fontStyle: 'normal',
            // color: '#000',
          },
        },
        {
          props: { variant: 'body2' },
          style: {
            fontFamily: 'Poppins',
            fontSize: 14,
            fontWeight: 400,
            fontStyle: 'normal',
            // color: '#000',
          },
        },
        {
          props: { variant: 'subtitle1' },
          style: {
            fontFamily: 'Poppins',
            fontWeight: 400,
            fontSize: 12,
            fontStyle: 'normal',
            // color: '#000',
          },
        },
        {
          props: { variant: 'subtitle2' },
          style: {
            fontFamily: 'sans-serif',
            fontWeight: 400,
            fontSize: 10,
            fontStyle: 'normal',
            // color: '#000',
          },
        },
        {
          props: { variant: 'caption' },
          style: {
            fontFamily: 'Poppins',
          },
        },
        {
          props: { variant: 'button' },
          style: {
            fontSize: 24,
            fontStyle: 'Poppins',
          },
        },
      ],
    },
    MuiTextField: {
      defaultProps: {
        fontFamily: 'Poppins',
      },
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            fontSize: 24,
            fontStyle: 'Poppins',
          },
        },
      ],
    },
  },
  typography: {
    fontFamily: 'Poppins',
    h1: {
      fontFamily: 'Poppins',
      fontSize: 60,
      fontWeight: 500,
      fontStyle: 'normal',
      // color: '#000',
    },
    h2: {
      fontFamily: 'Poppins',
      fontSize: 38,
      fontWeight: 600,
      fontStyle: 'normal',
      // color: '#000',
      lg: {
        fontSize: 26,
      },
      md: {
        fontSize: 22,
      },
      sm: {
        fontSize: 18,
      },
    },
    h3: {
      fontFamily: 'Poppins',
      fontSize: 22,
      fontWeight: 500,
      fontStyle: 'normal',
      // color: '#000',
    },
    h4: {
      fontFamily: 'Poppins',
      fontSize: 14,
      fontStyle: 'normal',
      // color: '#000',
      lg: {
        fontSize: 18,
      },
      md: {
        fontSize: 16,
      },
      sm: {
        fontSize: 13,
      },
    },
    h5: {
      fontFamily: 'Poppins',
      fontSize: 20,
      fontWeight: 400,
      fontStyle: 'normal',
      // color: '#000',
    },
    h6: {
      fontFamily: 'Poppins',
      fontSize: 14,
      fontWeight: 400,
      fontStyle: 'normal',
      // color: '#000',
    },
    body1: {
      fontFamily: 'Poppins',
      fontSize: 16,
      fontWeight: 400,
      fontStyle: 'normal',
      // color: '#000',
    },
    body2: {
      fontFamily: 'Poppins',
      fontSize: 12,
      fontWeight: 400,
      fontStyle: 'normal',
      // color: '#000',
    },
    subtitle1: {
      fontFamily: 'Poppins',
      fontWeight: 400,
      fontSize: 10,
      fontStyle: 'normal',
      // color: '#000',
    },
    subtitle2: {
      fontFamily: 'sans-serif',
      fontWeight: 400,
      fontSize: 8,
      fontStyle: 'normal',
      // color: '#000',
    },
  },
  spacing: 8,
  shape: {
    cardBorderRadius: 16,
  },
})

export default theme
