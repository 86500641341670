import { Grid, Typography } from '@mui/material'
import useStyles from './style'
import kinderGardenList from '../../Models/Academics/KinderGarden'
import highSchoolList from '../../Models/Academics/HigherSecondary'
import neetCoachingList from '../../Models/Academics/Neet'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import TextDivider from '../../Components/TextDivider'

const Academics = () => {
  const classes = useStyles()
  return (
    <>
      <section className={classes.container}>
        {/* Kinder Garden */}
        <Typography
          variant="h4"
          sx={{ fontWeight: 600 }}
          className={classes.title}
          style={{ paddingBlockStart: 20 }}
        >
          Kinder Garten
        </Typography>
        <TextDivider />
        <Grid container className={classes.root}>
          <Grid item xs={6} style={{ height: '100%' }}>
            <img
              src="/assets/images/academics/kinderGarden.jpeg"
              alt="kinderGarten"
              className={classes.img}
            />
          </Grid>
          <Grid item xs={6} className={classes.listWrapper}>
            {kinderGardenList?.map((data) => (
              <section className={classes.list} key={data}>
                <div>
                  <KeyboardArrowRightIcon
                    sx={{ fontSize: 14 }}
                    className={classes.bulletListIcon}
                  />
                </div>
                <div>
                  <Typography variant="body1" className={classes.listText}>
                    {data}
                  </Typography>
                </div>
              </section>
            ))}
          </Grid>
        </Grid>

        {/* Matric */}
        <Typography
          variant="h4"
          sx={{ fontWeight: 600 }}
          style={{ paddingBlockStart: 20 }}
          className={classes.title}
        >
          Matric
        </Typography>
        <TextDivider />
        <Grid container className={classes.matricRoot}>
          <Grid item xs={6} className={classes.listWrapper}>
            <section className={classes.list}>
              <div>
                <KeyboardArrowRightIcon
                  sx={{ fontSize: 14 }}
                  className={classes.bulletListIcon}
                />
              </div>
              <div>
                <Typography variant="body1" className={classes.listText}>
                  Admissions are sought from I to IX depending on the age and
                  Merit basis. Teaching develops life skills, Knowledge,
                  behavioral changes, responsibility, Discipline, Devotion and
                  Dedication .
                </Typography>
              </div>
            </section>

            {/* Teaching Method */}
            <Typography
              sx={{ fontWeight: 700 }}
              variant="body1"
              className={classes.listText}
            >
              Teaching Method :
            </Typography>
            <section className={classes.list}>
              <div>
                <KeyboardArrowRightIcon
                  sx={{ fontSize: 14 }}
                  className={classes.bulletListIcon}
                />
              </div>
              <div>
                <Typography variant="body1" className={classes.listText}>
                  The School adopts new methods by way of CBT’s ,Role play
                  ,Debates, Experiments, Teaching Aids , Digital classrooms more
                  than the traditional black board method. Learning by doing
                  enhances practical skills which enrich the students’
                  knowledge.
                </Typography>
              </div>
            </section>
            <section className={classes.list}>
              <div>
                <KeyboardArrowRightIcon
                  sx={{ fontSize: 14 }}
                  className={classes.bulletListIcon}
                />
              </div>
              <div>
                <Typography variant="body1" className={classes.listText}>
                  Simple projects are given to aid self learning and self
                  confidence.
                </Typography>
              </div>
            </section>

            {/* Curriculum */}
            <Typography
              sx={{ fontWeight: 700 }}
              variant="body1"
              className={classes.listText}
            >
              Curriculum :
            </Typography>
            <section className={classes.list}>
              <div>
                <KeyboardArrowRightIcon
                  sx={{ fontSize: 14 }}
                  className={classes.bulletListIcon}
                />
              </div>
              <div>
                <Typography variant="body1" className={classes.listText}>
                  English is the medium of instruction. Communication is made in
                  English to meet the challenges of the competitive world. The
                  second language offered is Tamil and Hindi is offered as third
                  language exam conducted by Students appear for Dakshina
                  Bharath Hindi Prachar Sabha and obtain certificates.
                </Typography>
              </div>
            </section>
            <section className={classes.list}>
              <div>
                <KeyboardArrowRightIcon
                  sx={{ fontSize: 14 }}
                  className={classes.bulletListIcon}
                />
              </div>
              <div>
                <Typography variant="body1" className={classes.listText}>
                  Subjects offered from I to X Std are Language, Science,
                  Mathematics, Social Science, Value Education, General
                  Knowledge, Computer Application.
                </Typography>
              </div>
            </section>
          </Grid>
          <Grid item xs={6} style={{ height: '100%' }}>
            <img
              src="/assets/images/academics/matric.jpg"
              alt="kinderGarden"
              className={classes.matricImg}
            />
          </Grid>
        </Grid>

        {/* Higher Secondary */}
        <Typography
          variant="h4"
          sx={{ fontWeight: 600 }}
          style={{ paddingBlockStart: 20 }}
          className={classes.title}
        >
          Higher Secondary
        </Typography>
        <TextDivider />
        <Grid container className={classes.higherSecRoot}>
          <Grid item xs={6} style={{ height: '100%' }}>
            <img
              src="/assets/images/academics/highSchool.jpeg"
              alt="kinderGarden"
              className={classes.img}
            />
          </Grid>
          <Grid item xs={6} className={classes.listWrapper}>
            {highSchoolList?.map((data) => (
              <section className={classes.list} key={data}>
                <div>
                  <KeyboardArrowRightIcon
                    sx={{ fontSize: 14 }}
                    className={classes.bulletListIcon}
                  />
                </div>
                <div>
                  <Typography variant="body1" className={classes.listText}>
                    {data}
                  </Typography>
                </div>
              </section>
            ))}
          </Grid>
        </Grid>

        {/* NEET Coaching */}
        <Typography
          variant="h4"
          sx={{ fontWeight: 600 }}
          style={{ paddingBlockStart: 20 }}
          className={classes.title}
        >
          NEET Coaching
        </Typography>
        <TextDivider />
        <Grid container className={classes.higherSecRoot}>
          <Grid item xs={6} className={classes.listWrapper}>
            {neetCoachingList?.map((data) => (
              <section className={classes.list} key={data}>
                <div>
                  <KeyboardArrowRightIcon
                    sx={{ fontSize: 14 }}
                    className={classes.bulletListIcon}
                  />
                </div>
                <div>
                  <Typography variant="body1" className={classes.listText}>
                    {data}
                  </Typography>
                </div>
              </section>
            ))}
          </Grid>
          <Grid item xs={6} style={{ height: '100%' }}>
            <img
              src="/assets/images/academics/neet.jpg"
              alt="kinderGarden"
              className={classes.img}
            />
          </Grid>
        </Grid>
      </section>
    </>
  )
}

export default Academics
