import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBlock: 8,
  },

  title: {
    color: theme.palette.custom.text,
    paddingBlockStart: 20,
  },
  cardContainer: {
    gap: 16,
    paddingBlockStart: 20,
  },
  img: {
    width: '100%',
    height: 200,
  },
  label: {
    color: theme.palette.custom.text,
    fontFamily: 'Lato !important',
    paddingBlockEnd: 4,
  },
  cardItem: {
    minHeight: 350,
    paddingBlockEnd: 16,
  },
  card: {
    height: '100%',
  },
  listContainer: {
    paddingBlockStart: 20,
  },
  list: {
    display: 'flex',
    alignItems: 'baseline',
    paddingBlock: 6,
  },
  bulletListIcon: {
    color: theme.palette.custom.text,
  },
  listText: {
    fontFamily: 'Lato !important',
    color: theme.palette.custom.text,
    paddingInline: 8,
  },
}))

export default useStyles
