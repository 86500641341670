import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  layout: {
    background:
      'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(183,183,187,0.3253676470588235) 0%)',
    paddingInline: 100,
    paddingBlockStart: -10,
    paddingBlockEnd: 20,
  },
}))

export default useStyles
