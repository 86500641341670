import { makeStyles } from '@mui/styles'
import { Divider } from '@mui/material'

const useStyles = makeStyles((theme) => ({
  divider: {
    width: 70,
    paddingBlock: 8,
    '& .MuiDivider-root': {
      border: `1px solid ${theme.palette.custom.divider}`,
    },
  },
}))

const TextDivider = () => {
  const classes = useStyles()
  return (
    <>
      <section className={classes.divider}>
        <Divider />
      </section>
    </>
  )
}

export default TextDivider
