import { Typography, Grid, Card, CardMedia, CardContent } from '@mui/material'
import useStyles from './style'
import TextDivider from '../../Components/TextDivider'
import cardData from '../../Models/Facilities/Card'
import hostelFacilities from '../../Models/Facilities/Hostel'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'

const Facilities = () => {
  const classes = useStyles()
  return (
    <>
      <div className={classes.root}>
        <Typography
          variant="h4"
          sx={{ fontWeight: 600 }}
          className={classes.title}
        >
          Facilities
        </Typography>
        <TextDivider />
        <Grid container className={classes.cardContainer}>
          {cardData?.map((data) => (
            <Grid item xs={3.8} key={data?.title} className={classes.cardItem}>
              <Card className={classes.card}>
                <CardMedia
                  component="img"
                  className={classes.img}
                  image={data?.img}
                  alt={data?.title}
                />
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    className={classes.title}
                  >
                    {data?.title}
                  </Typography>
                  <Typography variant="body2" className={classes.label}>
                    {data?.content}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Typography
          variant="h4"
          sx={{ fontWeight: 600 }}
          className={classes.title}
        >
          Hostel Facilities
        </Typography>
        <TextDivider />
        <section className={classes.listContainer}>
          {hostelFacilities?.map((data) => (
            <section className={classes.list} key={data}>
              <div>
                <KeyboardArrowRightIcon
                  sx={{ fontSize: 14 }}
                  className={classes.bulletListIcon}
                />
              </div>
              <div>
                <Typography variant="h5" className={classes.listText}>
                  {data}
                </Typography>
              </div>
            </section>
          ))}
        </section>
      </div>
    </>
  )
}

export default Facilities
