import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    // height: 400,
    paddingInline: 100,
    backgroundImage: 'url(assets/images/footer/care-footer.svg)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundAttachment: 'fixed',
    paddingBlockStart: 40,
    paddingBlockEnd: 60,
  },
  addressGrid: {
    display: 'flex',
    justifyContent: 'center',
  },
  schoolName: {
    color: theme.palette.custom.dark,
    paddingBlock: 12,
  },
  addressContainer: {
    display: 'flex',
    gap: 12,
    paddingBlock: 8,
  },
  emailContainer: {
    display: 'inline-flex',
    gap: 12,
    cursor: 'pointer',
    paddingBlock: 8,
  },
  schoolAddress: {
    color: theme.palette.custom.dark,
    display: 'flex',
    alignItems: 'flex-start',
  },
  otherEnquiry: {
    color: theme.palette.custom.light,
  },
  mapContainer: {
    height: 260,
    borderRadius: 10,
    width: '100%',
  },
  map: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    cursor: 'pointer',
  },
}))

export default useStyles
