import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  scrollBarContainer: {
    scrollbarWidth: 'none',
    '@global': {
      '::-webkit-scrollbar': {
        width: 0,
      },

      /* Handle */
      '::-webkit-scrollbar-thumb': {
        background: '#7047EA00',
        borderRadius: 50,
      },

      /* Handle on hover */
      '::-webkit-scrollbar-thumb:hover': {
        background: '#7047EA00',
      },
    },
    // [theme.breakpoints.down('xs')]: {
    //   display: 'none',
    // },
  },
  root: {
    position: 'fixed',
    zIndex: 999,
    width: 74,
    overflowY: 'auto',
    overflowX: 'hidden',
    height: 'calc(100vh - 100px)',
    maxWidth: 360,
    background: theme.palette.custom.main,
    borderRadius: 24,
    transition: 'width 0.5s',
    transform: 'translate(12px,14px)',
    '& .MuiList-root': {
      paddingBlockStart: 20,
      paddingInlineStart: 8,
      paddingInlineEnd: 8,
    },
    '& .MuiListItem-root': {
      paddingBlock: 14,
    },
    '& .MuiListItem-button': {
      '&:hover': {
        background: '#FFFFFF33 0% 0% no-repeat padding-box',
      },
    },
    '& .activeMenu': {
      '& .MuiListItem-root': {
        background: '#FFFFFF33 0% 0% no-repeat padding-box',
        '&:hover': {
          background: '#FFFFFF33 0% 0% no-repeat padding-box',
        },
      },
    },
    '& .MuiSvgIcon-root': {
      color: '#fff',
    },
    '&:hover': {
      width: 320,
    },
  },
  scrollLink: {
    display: 'contents',
    '& .MuiListItem-root': {
      marginBottom: 10,
      borderRadius: 10,
    },
  },
  menuLabel: {
    color: '#fafafa',
    whiteSpace: 'nowrap',
  },
}))

export default useStyles
