export const languagesRow = [
  { sNo: 1, part: 'Part - I', subjects: 'Tamil / Hindi' },
  { sNo: 2, part: 'Part - II', subjects: 'English' },
]

export const groupsRow = [
  {
    groupNo: 'Group - I',
    subjects: 'Physics, Chemistry, Mathematics, Computer Science',
  },
  {
    groupNo: 'Group - II',
    subjects: 'Physics, Chemistry, Mathematics, Biology',
  },
  {
    groupNo: 'Group - III',
    subjects: 'Physics, Chemistry, Biology, Computer Science',
  },
  {
    groupNo: 'Group - IV',
    subjects: 'Accountancy, Commerce, Economics, Computer Application',
  },
  {
    groupNo: 'Group - V',
    subjects: 'Accountancy, Commerce, Economics, Business Mathematics',
  },
  {
    groupNo: 'Group - VI',
    subjects: 'Basic Mechanical Engineering, Mathematics, Employability Skills',
  },
]
