import useStyles from './style'
// Import Swiper core components
import SwiperCore, { EffectCoverflow } from 'swiper/core'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper'
// Import Swiper styles
import 'swiper/css'
import sliderData from '../../Models/Home/BlockSlider'
import { Typography } from '@mui/material'

SwiperCore.use([EffectCoverflow])

const BlockImageSlider = () => {
  const classes = useStyles()
  return (
    <section className={classes.root}>
      <Swiper
        modules={[Autoplay]}
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={5}
        coverflowEffect={{
          rotate: 20,
          stretch: 0,
          depth: 200,
          modifier: 1,
          slideShadows: false,
        }}
        pagination={{ clickable: true }}
        loop={true}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        // spaceBetween={0}
        className={classes.sliderWrapper}
        onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper) => console.log(swiper)}
        breakpoints={{
          315: {
            slidesPerView: 1,
          },
          340: {
            slidesPerView: 2,
          },
          480: {
            slidesPerView: 3,
          },
          640: {
            slidesPerView: 4,
          },
          840: {
            slidesPerView: 4,
          },
          1200: {
            slidesPerView: 4,
          },
        }}
      >
        {sliderData?.map((data) => (
          <SwiperSlide key={data?.name} style={{ width: 300 }}>
            <section className={classes.imgContainer}>
              <img
                src={data?.imgUrl}
                alt={data?.name}
                className={classes.sliderImg}
              />
            </section>
            <section className={classes.titleWrapper}>
              <Typography
                sx={{ fontWeight: 'bold' }}
                variant="body1"
                className={classes.title}
              >
                {data?.name}
              </Typography>
            </section>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  )
}

export default BlockImageSlider
