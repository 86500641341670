import useStyles from './style'
import { Grid, Typography } from '@mui/material'
import PublicIcon from '@mui/icons-material/Public'
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone'
import EmailIcon from '@mui/icons-material/Email'
import { useNavigate } from 'react-router-dom'

const Footer = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  return (
    <>
      <Grid container className={classes.root}>
        <Grid item xs={6} className={classes.addressGrid}>
          <section>
            <Typography
              sx={{ fontWeight: 600 }}
              variant="h4"
              className={classes.schoolName}
            >
              SRSI Matric Higher Secondary School
            </Typography>

            {/* Address */}
            <section className={classes.addressContainer}>
              <section>
                {' '}
                <PublicIcon className={classes.schoolAddress} />
              </section>
              <section>
                {' '}
                <Typography
                  sx={{ fontWeight: 600 }}
                  variant="h5"
                  className={classes.schoolAddress}
                >
                  SNR Venkatesalu Nagar, Karamadai(Po) ,
                  <br />
                  Coimbatore , Tamil Nadu <br />
                  641104
                </Typography>
              </section>
            </section>

            {/* Phone Number */}
            <section className={classes.addressContainer}>
              <section>
                {' '}
                <PhoneIphoneIcon className={classes.schoolAddress} />
              </section>
              <section>
                <Typography
                  sx={{ fontWeight: 600 }}
                  variant="h5"
                  className={classes.schoolAddress}
                >
                  For Admission : +91 8300192777, 8300242427
                </Typography>
              </section>
            </section>

            {/* Email Number */}
            <section className={classes.emailContainer}>
              <section>
                {' '}
                <EmailIcon className={classes.schoolAddress} />
              </section>
              <section>
                <Typography
                  sx={{ fontWeight: 600 }}
                  variant="h5"
                  className={classes.schoolAddress}
                  onClick={() =>
                    (window.location = 'mailto:yourmail@domain.com')
                  }
                >
                  srsi_mhss@yahoo.com
                </Typography>
              </section>
            </section>

            {/* Other Enquiry */}
            {/* <section>
              <Typography
                sx={{ fontWeight: 300 }}
                variant="h5"
                className={classes.otherEnquiry}
              >
                For Other Enquiry : +91 8300192777
              </Typography>
            </section> */}
          </section>
        </Grid>
        <Grid item xs={6} className={classes.mapContainer}>
          <img
            src="/assets/images/map1.png"
            alt="map"
            className={classes.map}
            onClick={() =>
              window.open(
                'https://www.google.com/maps/place/SRSI+Matriculation+Higher+Secondary+School/@11.2640306,76.9560279,1690m/data=!3m1!1e3!4m14!1m7!3m6!1s0x3ba8ee4f16e3cc69:0x8b18090de8bae594!2sSRSI+Matriculation+Higher+Secondary+School!8m2!3d11.2645201!4d76.955279!16s%2Fg%2F1vfp6n1w!3m5!1s0x3ba8ee4f16e3cc69:0x8b18090de8bae594!8m2!3d11.2645201!4d76.955279!16s%2Fg%2F1vfp6n1w',
                '_blank'
              )
            }
          />
          {/* <iframe
            title="SRSI Matric Hr Sec School"
            width="100%"
            height="100%"
            style={{ border: 0 }}
            // frameBorder="0"
            // scrolling="no"
            // marginHeight="0"
            // marginWidth="0"
            src="https://maps.google.com/maps?width=100%25&amp;height=260&amp;hl=en&amp;q=SRSI%20%20Matriculation%20Higher%20Secondary%20School,%20school,%20India+(srsi%20school)&amp;t=k&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
          ></iframe> */}
          <br />
        </Grid>
      </Grid>
    </>
  )
}

export default Footer
