import useStyles from './style'
import BlockImageSlider from '../../Components/BlockImageSlider'
import { Typography, Grid } from '@mui/material'
import TextDivider from '../../Components/TextDivider'
import list from '../../Models/Home/WhySRSI'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'

const Home = () => {
  const classes = useStyles()
  return (
    <div>
      <BlockImageSlider />
      <Typography
        variant="h4"
        sx={{ fontWeight: 600 }}
        className={classes.title}
      >
        SRSI School
      </Typography>
      <TextDivider />
      <section className={classes.founderWrapper}>
        <section className={classes.founderContainer}>
          <div className={classes.founderCard}>
            <img
              src="/assets/images/home/founder2.jpg"
              alt="SEVARATNA DR.R.VENKATESALU AND SMT.VELUMANIAMMAL"
              className={classes.founderImg}
            />
            <Typography variant="h5" sx={{ fontWeight: 600 }}>
              SEVARATNA DR.R.VENKATESALU AND SMT.VELUMANIAMMAL
            </Typography>
            <Typography variant="h5">Founders of our institution </Typography>
          </div>
          <div className={classes.founderCard}>
            <img
              src="/assets/images/home/founder1.png"
              alt="Sri.V.Ramakrishnan"
              className={classes.founderImg}
            />
            <Typography variant="h5" sx={{ fontWeight: 600 }}>
              Sri.V.RAMAKRISHNAN
            </Typography>
            <Typography variant="h5">Former Managing Trustee</Typography>
          </div>
        </section>
        {/* <div className={classes.founderCaption}>
          <Typography variant="h5">Founders of our institution </Typography>
        </div> */}
      </section>
      <Typography
        sx={{ lineHeight: 2 }}
        variant="h5"
        className={classes.aboutSchool}
      >
        SRSI matriculation Higher Secondary School is a co-educational day-cum
        residential school situated near the temple town of Karamadai on the
        Mettupalayam Coimbatore highway. The school is located in a sylvan
        surrounding free from the noise and pollution. It is our strong
        commitment and our mission to inculcate the values of Dedication
        Devotion and Discipline and bring about a transformational experience in
        every student. The students are given ample and equal opportunity with
        unique teaching methods to bring about academic excellence and to
        nurture talent and skill. The founders of our school are Sevaratna Sri
        R.Venkatesalu and Smt. Velumaniammal of the renowned SNR Family of
        Coimbatore. It was their dream and it was their vision that was
        instrumental in establishing an “English medium school” at Karamadai
        that would rank among the best in imparting quality education and
        contribute to the overall development of the students from rural area in
        1981. Since the inception of our esteemed institution, Our Former
        managing trustee Sri.V.Ramakrishnan had envisioned on quality education
        for 42 years, he ensured that the institution not only imparted
        knowledge but also, he had seen to the moral & physical well-being of
        the pupils. It was upgraded into a higher Secondary School in 1990. The
        new academic block was declared open by the then President of India, His
        excellency Sri.R.Venkatraman. Today the school stands as a full fledged
        Matric. Hr. Sec. School with student strength of 2000 and the staff
        strength of 125. Under the inspiring leadership and guidance of our
        chairman Sri.V.Gopalakrishnan the school is achieving success on every
        field of education.
      </Typography>
      <Typography
        variant="h4"
        style={{ paddingBlockStart: 20 }}
        className={classes.title}
        sx={{ fontWeight: 600 }}
      >
        Why choose SRSI?
      </Typography>
      <TextDivider />
      <section className={classes.listContainer}>
        {list?.map((data) => (
          <section className={classes.list} key={data}>
            <div>
              <KeyboardArrowRightIcon
                sx={{ fontSize: 14 }}
                className={classes.bulletListIcon}
              />
            </div>
            <div>
              <Typography variant="h5" className={classes.listText}>
                {data}
              </Typography>
            </div>
          </section>
        ))}
      </section>
      <Typography
        sx={{ lineHeight: 2 }}
        variant="h5"
        className={classes.contentText}
      >
        The Alumni of SRSI are all highly placed and many hold key posts in
        renowned organization across the globe.
      </Typography>
    </div>
  )
}

export default Home
