const admins = [
  {
    img: 'assets/images/admins/Jayakannan.jpg',
    name: 'Mr.S.Jayakannan',
    position: 'Mr.S.Jayakannan',
    workPlace: 'Secretary',
  },
  {
    img: 'assets/images/admins/admin2.jpg',
    name: 'Mrs.S.Saraswathi ',
    position: 'Mrs.S.Saraswathi ',
    workPlace: 'Principal',
  },
  {
    img: 'assets/images/admins/Moorthy.jpg',
    name: 'Mr.P.Moorthy',
    position: 'Mr.P.Moorthy',
    workPlace: 'Co-ordinator',
  },
]

export default admins
