// import { useState } from 'react'
import useStyles from './style'
import { List, ListItem, Typography, Box, ListItemIcon } from '@mui/material'
import { Link } from 'react-scroll'
import HomeIcon from '@mui/icons-material/Home'
import ContactPageIcon from '@mui/icons-material/ContactPage'
import BallotIcon from '@mui/icons-material/Ballot'
import FormatShapesIcon from '@mui/icons-material/FormatShapes'
import SchoolIcon from '@mui/icons-material/School'
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents'

const menuList = [
  {
    menuPath: 'home',
    menuName: 'Home',
    imageUrl: <HomeIcon />,
  },
  {
    menuPath: 'about',
    menuName: 'About',
    imageUrl: <ContactPageIcon />,
  },
  {
    menuPath: 'facilities',
    menuName: 'Facilities',
    imageUrl: <BallotIcon />,
  },
  {
    menuPath: 'admission',
    menuName: 'Admission',
    imageUrl: <FormatShapesIcon />,
  },
  {
    menuPath: 'academics',
    menuName: 'Academics',
    imageUrl: <SchoolIcon />,
  },
  {
    menuPath: 'awards',
    menuName: 'Awards & Acheievements',
    imageUrl: <EmojiEventsIcon />,
  },
]

const Sidebar = () => {
  const classes = useStyles()
  // const [selectedIndex, setSelectedIndex] = useState(0)
  // Side menu bar handling BG color and current Index
  const handleMenuList = (event, currentIndex) => {
    // setSelectedIndex(currentIndex)
  }
  return (
    <>
      <section className={classes.scrollBarContainer}>
        <Box className={classes.root}>
          <List component={'nav'}>
            {menuList.map((item, idx) => (
              <Link
                key={item?.menuName}
                onSetActive={(event) => {
                  handleMenuList(event, idx)
                }}
                activeClass={'activeMenu'}
                to={item?.menuPath}
                spy={true}
                duration={500}
                offset={-60}
                smooth={true}
                className={classes.scrollLink}
              >
                {/*  */}
                <ListItem
                  key={item?.menuName}
                  activeClass={'activeMenu'}
                  button
                  onClick={(event) => handleMenuList(event, idx)}
                >
                  <ListItemIcon>
                    {/* <img
                      alt="icon"
                      src={item?.imageUrl}
                      width={24}
                      height={24}
                    /> */}
                    {item?.imageUrl}
                  </ListItemIcon>
                  <Typography variant="h5" className={classes.menuLabel}>
                    {item?.menuName}
                  </Typography>
                </ListItem>
              </Link>
            ))}
          </List>
        </Box>
      </section>
    </>
  )
}

export default Sidebar
