import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    color: theme.palette.custom.text,
  },
  founderWrapper: {
    paddingBlockStart: 20,
  },
  founderCaption: {
    '& .MuiTypography-root': {
      fontSize: 20,
      textAlign: 'center',
      color: '#4c4c4c',
      fontWeight: 500,
      paddingBlockStart: 12,
    },
  },
  founderContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    columnGap: 40,
    rowGap: 16,
    paddingBlockStart: 20,
  },
  founderCard: {
    width: 360,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    '& .MuiTypography-root': {
      fontSize: 15,
      color: '#4c4c4c',
      // fontWeight: 500,
      paddingBlockStart: 8,
    },
  },
  founderImg: {
    width: 300,
    height: 248,
  },
  aboutSchool: {
    color: theme.palette.custom.text,
    textAlign: 'justify',
    paddingBlockEnd: 8,
    fontFamily: 'Lato !important',
    paddingBlockStart: 20,
  },
  listContainer: {
    paddingBlockStart: 20,
  },
  list: {
    display: 'flex',
    alignItems: 'baseline',
    paddingBlock: 6,
  },
  bulletListIcon: {
    color: theme.palette.custom.text,
  },
  listText: {
    fontFamily: 'Lato !important',
    color: theme.palette.custom.text,
    paddingInline: 8,
  },
  contentText: {
    fontFamily: 'Lato !important',
    color: theme.palette.custom.text,
    paddingBlockEnd: 8,
    paddingBlockStart: 6,
  },
}))

export default useStyles
