import { CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { ThemeProvider as ThemeProviderStyle } from '@mui/styles'
import theme from './Theme'
import Router from './Router'

const App = () => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <ThemeProviderStyle theme={theme}>
          <CssBaseline />
          <Router />
        </ThemeProviderStyle>
      </ThemeProvider>
    </>
  )
}

export default App
