import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    height: 70,
    background: '#fff',
    // boxShadow: '0px 2px 7px 0px rgba(150,147,150,1)',
    boxShadow: '0px 3px 6px #00000026',
    alignItems: 'center',
    paddingInline: 30,
    position: 'sticky',
    top: 0,
    right: 0,
    left: 0,
    zIndex: 999,
  },
  logo: {
    height: 50,
    width: 50,
  },
  contactContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 32,
  },
  contactWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    color: theme.palette.custom.main,
    fontSize: 32,
  },
  contentWrapper: {
    paddingInlineStart: 8,
  },
}))

export default useStyles
