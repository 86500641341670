import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingBlockStart: 12,
  },
  root: {
    background: '#fff',
    borderRadius: 10,
    marginBlockStart: 20,
    [theme.breakpoints.up('md')]: {
      height: 380,
    },
    [theme.breakpoints.up('lg')]: {
      height: 340,
    },
  },
  matricRoot: {
    background: '#fff',
    borderRadius: 10,
    marginBlockStart: 20,
    [theme.breakpoints.up('md')]: {
      height: 600,
    },
    [theme.breakpoints.up('lg')]: {
      height: 480,
    },
  },

  higherSecRoot: {
    background: '#fff',
    borderRadius: 10,
    marginBlockStart: 20,
    [theme.breakpoints.up('md')]: {
      height: 340,
    },
    [theme.breakpoints.up('lg')]: {
      height: 300,
    },
  },
  title: {
    color: theme.palette.custom.text,
  },
  img: {
    width: '100%',
    height: '100%',
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
  },
  matricImg: {
    width: '100%',
    height: '100%',
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
  },
  listWrapper: {
    alignSelf: 'center',
    paddingInlineStart: 12,
  },
  list: {
    display: 'flex',
    alignItems: 'baseline',
    paddingBlock: 4,
  },
  bulletListIcon: {
    color: theme.palette.custom.text,
  },
  listText: {
    fontFamily: 'Lato !important',
    color: theme.palette.custom.text,
    paddingInline: 8,
  },
}))

export default useStyles
