import useStyles from './style'
import { Grid, Typography } from '@mui/material'
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk'
import EmailIcon from '@mui/icons-material/Email'

const Header = () => {
  const classes = useStyles()
  return (
    <>
      <Grid container className={classes.root}>
        {/* Logo */}
        <Grid item xs={2}>
          <img
            src="/assets/images/logos/school-icon.png"
            alt="logo"
            className={classes.logo}
          />
        </Grid>
        {/* Contacts */}
        <Grid item xs={10} className={classes.contactContainer}>
          {/* Phone Contact */}
          <Grid item className={classes.contactWrapper}>
            <Grid item className={classes.iconWrapper}>
              <PhoneInTalkIcon
                className={classes.icon}
                size={'large'}
                sx={{ fontSize: 36 }}
              />
            </Grid>
            <Grid item className={classes.contentWrapper}>
              <Grid item>
                <Typography variant="body1">Call us@</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">8300192777, 8300242427</Typography>
              </Grid>
            </Grid>
          </Grid>
          {/* Email Contact */}
          <Grid item className={classes.contactWrapper}>
            <Grid item className={classes.iconWrapper}>
              <EmailIcon
                className={classes.icon}
                size={'large'}
                sx={{ fontSize: 36 }}
              />
            </Grid>
            <Grid item className={classes.contentWrapper}>
              <Grid item>
                <Typography variant="body1">Email us@</Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="body2"
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    (window.location = 'mailto:yourmail@domain.com')
                  }
                >
                  srsimhssschool@gmail.com
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default Header
