const boardMembers = [
  {
    img: '/assets/images/boardMembers/trustee1.jpg',
    name: 'Sri.V.Gopalakrishnan',
    position: 'Sri.V.Gopalakrishnan',
    workPlace: 'Chairman',
  },
  {
    img: '/assets/images/boardMembers/trustee2.jpg',
    name: 'Smt.Sabitha Ramakrishnan',
    position: 'Smt.Sabitha Ramakrishnan',
    workPlace: 'Managing Trustee',
  },
  {
    img: '/assets/images/boardMembers/trustee3.jpg',
    name: 'Sri.R.Sundar',
    position: 'Sri.R.Sundar',
    workPlace: 'Managing Trustee',
  },
]

export default boardMembers
